<template>
   <div>
     <v-card class="mx-auto card flat-card mb-2" color="surface" @click="push(purse.Purse_ID)">
      <v-card-text class="pa-2">
        <v-row class="pa-2">
          <span class="onSurface--text mr-2 medium-font" v-text="purse.title" ></span>
          <v-spacer></v-spacer>
          <v-icon class="primaryText--text">mdi-chevron-left</v-icon>  
        </v-row>
        <v-row class="pa-2">
           <span class="onSurface--text medium-font mr-2">مانده: </span>
           <v-spacer></v-spacer>
           <div class="ml-2">
           <span v-text="purse.balance" class="medium-font onSurface--text ml-1"></span><span class="medium-font titleFont--text">ریال</span>
           </div>
        </v-row>
       </v-card-text>
     </v-card>
   </div>
</template>
<script>
import router from '@/router'
export default {
  props: ['purse'],
   methods: {
        push(Purse_ID) {
          router.push(`/transaction/${Purse_ID}`)
        }
    }
}
</script>